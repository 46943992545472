import React, { useState, useEffect } from 'react';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';

import {
  CategoryNavigator,
  FullWidthBarWithLeaves,
  QuadripleArticleArrangement,
  TopArticles,
  ArticleCard,
  ArticleTag,
} from '@components';

import styles from './category.module.scss';

export const query = graphql`
  query CategoryQuery($categoryName: String!) {
    category: strapiCategory(name: { eq: $categoryName }) {
      image {
        localFile {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allStrapiArticle: allStrapiArticle(
      filter: {
        status: { eq: "published" }
        category: { name: { eq: $categoryName } }
      }
    ) {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
    topPageViews: allPageViews(
      filter: { path: { regex: "/article/" } }
      sort: { fields: totalCount, order: DESC }
    ) {
      edges {
        node {
          id
          totalCount
        }
      }
    }
    categoryArticles: allStrapiArticle(
      filter: {
        status: { eq: "published" }
        category: { name: { eq: $categoryName } }
      }
    ) {
      edges {
        node {
          id
          description
          title
          strapiId
          slug
          category {
            name
          }
          image {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          tags {
            tag
          }
        }
      }
    }
  }
`;

const CategoryPage = ({ data, pageContext }) => {
  const [archiveArticles, setArchiveArticles] = useState(
    data.categoryArticles.edges
  );
  const [filterList, setFilterList] = useState([]);

  const clearFilters = () => setFilterList([]);

  const categoryImage = data.category.image;
  const allArticles = data.allStrapiArticle.edges;
  const categoryName = pageContext.categoryName;
  const topPageViews = data.topPageViews.edges;

  const categoryArticles = data.categoryArticles.edges;

  const generateCategoryCards = categoryArticles => {
    return categoryArticles.map(article => {
      const {
        node: {
          category: { name },
          description,
          image,
          title,
          id,
          slug,
        },
      } = article;

      return (
        <ArticleCard
          key={id}
          title={title}
          description={description}
          tag={name}
          // classNames={articleCardStyles}
          image={image}
          url={slug}
          hideTags
        />
      );
    });
  };

  const tagsForCurrentCategory = data.categoryArticles.edges.flatMap(
    article => {
      const {
        node: { tags },
      } = article;
      return tags?.map(e => e.tag);
    }
  );

  const tagsDisplayed = [...new Set(tagsForCurrentCategory)].map(e => (
    <div onClick={() => updateFilterList(e)}>
      <ArticleTag tag={e} key={e} isActive={filterList.includes(e)} />
    </div>
  ));

  const updateFilterList = tag => {
    if (filterList.includes(tag)) {
      setFilterList(filterList.filter(item => item !== tag));
    } else {
      const newFilterList = [...filterList];
      newFilterList.push(tag);
      setFilterList(newFilterList);
    }
  };

  const updateArchiveArticles = () => {
    const displayedArticles = [];
    if (filterList.length === 0) {
      setArchiveArticles(categoryArticles);
    } else {
      for (let filter of filterList) {
        for (let article of categoryArticles) {
          for (let tagObj of article.node.tags) {
            if (tagObj.tag === filter && !displayedArticles.includes(article)) {
              displayedArticles.push(article);
            }
          }
        }
      }
      setArchiveArticles(displayedArticles);
    }
  };

  useEffect(() => {
    updateArchiveArticles();
  }, [filterList]);

  const chooseTop8Articles = (allArticles, popularArticles) => {
    const results = [];
    for (const popularArticle of popularArticles) {
      const filteredArticle = allArticles.find(normalArticle =>
        popularArticle.node.id.includes(normalArticle.node.slug)
      );
      if (filteredArticle == null) {
        continue;
      } else {
        results.push(filteredArticle);
      }
      if (results.length >= 8) {
        break;
      }
    }
    return results;
  };

  return (
    <>
      <CategoryNavigator />
      <FullWidthBarWithLeaves classNames={styles}>
        <div className={styles.container}>
          <h2 className={styles.header}>{categoryName} Articles</h2>
          <div className={styles.subHeader}>
            <p>Looking for something? Filter articles by tags below.</p>
          </div>
        </div>
      </FullWidthBarWithLeaves>
      <div className={styles.popularArticlesSection}>
        <div className={styles.categoryImage}>
          <Img
            fluid={categoryImage.localFile.childImageSharp.fluid}
            alt='Category Image'
          />
        </div>
        <TopArticles articles={chooseTop8Articles(allArticles, topPageViews)} />
      </div>
      <div className={styles.archiveSection}>
        <h2>Archive</h2>
        {tagsDisplayed.length > 0 && (
          <span> I’m looking for {categoryName} resources related to: </span>
        )}
        <div className={styles.articleTags}>{tagsDisplayed}</div>
        <div className={styles.clear} onClick={clearFilters}>
          Clear all filters &#x2715;
        </div>
        <QuadripleArticleArrangement
          cards={generateCategoryCards(archiveArticles)}
        />
      </div>
    </>
  );
};

export default CategoryPage;
